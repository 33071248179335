import beneficiariesListStore from '@/modules/beneficiaries/store/beneficiaries-list-store';
import beneficiariesFormStore from '@/modules/beneficiaries/store/beneficiaries-form-store';

export default {
    namespaced: true,

    modules: {
        list: beneficiariesListStore,
        form: beneficiariesFormStore
    },
};
