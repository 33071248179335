import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';
import { BeneficiariesPermissions } from '../beneficiaries-permissions';

export const GET_BENEFICIARIES = 'getBeneficiaries';

export const SET_BENEFICIARIES = 'setBeneficiaries';
export const SET_SELECTED_BENEFICIARY = 'setSelectedBeneficiary';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_TOTAL = 'setTotal';
export const SET_FILTERS = 'setFilters';
export const SET_PARAMS = 'setParams';

const state = {
    beneficiaries: [],
    total: 0,
    currentPage: 1,
    filters: {},
    params: {},
};

const getters = {
    beneficiaries: state => state.beneficiaries,
    total: state => state.total,
    currentPage: state => state.currentPage,
    filters: state => state.filters,
    permissions: (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['auth/currentUser'];
        return {
            hasPermissionToEdit: new BeneficiariesPermissions(currentUser).edit,
            hasPermissionToDelete: new BeneficiariesPermissions(currentUser).destroy,
        }
    },
};

const actions = {
    [GET_BENEFICIARIES](context) {
        context.commit('shared/activateLoading', 'beneficiaries/getBeneficiaries', { root: true });
        const params = context.state.params;
        return new Promise((resolve) => {
            ApiService.query('beneficiaries', { params })
                .then(({ data }) => {
                    context.commit(SET_BENEFICIARIES, data.data);
                    context.commit(SET_TOTAL, data.pagination.total);
                    resolve(data);
                })
                .catch(error => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'beneficiaries/getBeneficiaries', { root: true });
                });
        });
    },
};

const mutations = {
    [SET_BENEFICIARIES](state, beneficiaries) {
        state.beneficiaries = beneficiaries;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_TOTAL](state, total) {
        state.total = total;
    },
    [SET_FILTERS](state, { key, value }) {
        state.filters[key] = value;
    },
    [SET_PARAMS](state, params) {
        state.params = params;
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
