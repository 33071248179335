import ApiService from '@/shared/services/api.service';
import BeneficiariesService from '@/modules/beneficiaries/beneficiaries-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import { BeneficiariesPermissions } from '../beneficiaries-permissions';

export const ADD_BENEFICIARY = 'addBeneficiary';
export const SELECT_BENEFICIARY = 'selectBeneficiary';
export const UPDATE_BENEFICIARY = 'updateBeneficiary';
export const DELETE_BENEFICIARY = 'deleteBeneficiary';

export const SET_SELECTED_BENEFICIARY = 'setSelectedBeneficiary';

const state = {
    selectedBeneficiary: null,
};

const getters = {
    selectedBeneficiary: state => state.selectedBeneficiary,
    permissions: (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['auth/currentUser'];
        return {
            hasPermissionToEdit: new BeneficiariesPermissions(currentUser).edit,
            hasPermissionToDelete: new BeneficiariesPermissions(currentUser).destroy,
        }
    },
};

const actions = {
    [ADD_BENEFICIARY](context, data) {
        context.commit('shared/activateLoading', 'beneficiaries/addBeneficiary', { root: true });
        return new Promise((resolve, reject) => {
            BeneficiariesService.addBeneficiary(data)
                .then((data) => {
                    Message.success('BENEFICIARIES.NOTIFIERS.ADDED_SUCCESS');
                    context.dispatch('beneficiaries/list/getBeneficiaries', {}, { root: true });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "beneficiaries/addBeneficiary",
                        { root: true }
                    );
                });
        });
    },
    [SELECT_BENEFICIARY](context, data) {
        context.commit('shared/activateLoading', 'beneficiaries/selectBeneficiary', { root: true });

        return new Promise((resolve, reject) => {
            BeneficiariesService.findBeneficiary(data)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "beneficiaries/updateBeneficiary",
                        { root: true }
                    );
                });
        });
    },
    [UPDATE_BENEFICIARY](context, data) {
        context.commit('shared/activateLoading', 'beneficiaries/updateBeneficiary', { root: true });

        return new Promise((resolve, reject) => {
            BeneficiariesService.updateBeneficiary(data)
                .then((data) => {
                    Message.success('BENEFICIARIES.NOTIFIERS.EDITED_SUCCESS');
                    context.dispatch('beneficiaries/list/getBeneficiaries', {}, { root: true });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "beneficiaries/updateBeneficiary",
                        { root: true }
                    );
                });
        });
    },
    [DELETE_BENEFICIARY](context, data) {
        context.commit('shared/activateLoading', 'beneficiaries/deleteBeneficiary', { root: true });
        return new Promise((resolve, reject) => {
            BeneficiariesService.deleteBeneficiary(data)
                .then((data) => {
                    Message.success('BENEFICIARIES.NOTIFIERS.DELETE_SUCCESS');
                    context.dispatch('beneficiaries/list/getBeneficiaries', {}, { root: true });
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit(
                        "shared/deactivateLoading",
                        "beneficiaries/deleteBeneficiary",
                        { root: true }
                    );
                });
        });
    },
};

const mutations = {
    [SET_SELECTED_BENEFICIARY](state, beneficiary) {
        state.selectedBeneficiary = beneficiary;
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
