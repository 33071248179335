import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class BeneficiariesPermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.beneficiariesRead,
        );
        this.create = permissionChecker.match(
            Permissions.values.beneficiariesCreate,
        );
        this.edit = permissionChecker.match(
            Permissions.values.beneficiariesEdit,
        );
        this.destroy = permissionChecker.match(
            Permissions.values.beneficiariesDestroy,
        );
    }
}
