import ApiService from '@/shared/services/api.service';

export default class BeneficiariesService {
    static addBeneficiary(data) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/beneficiaries?echo=true`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static updateBeneficiary(data) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/beneficiaries/${data.id}`, data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static findBeneficiary(data){
        return new Promise((resolve, reject) => {
            ApiService.query(`/beneficiaries/${data.id}`)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => reject(error));
        });
    }

    static deleteBeneficiary(id){
        return new Promise((resolve, reject) => {
            ApiService.delete(`/beneficiaries/${id}`)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => reject(error));
        });
    }
}